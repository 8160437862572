








import { Component, Vue } from "vue-property-decorator";
import AppLayout from "../components/commons/layouts/AppLayout.vue";

@Component({
  components: {
    AppLayout,
  },
})
export default class NotFoundPage extends Vue {}
